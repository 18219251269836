<template>
  <div>
    <BasicLogo width="300" />
    <div class="content-title">
      <BasicTitle title="Política de Privacidad" />
      <div class="box-content">
        <BasicSubtitle
          :secondary="true"
          subtitle="Las presentes políticas de privacidad (en adelante, la “Política de Privacidad”) describen la forma y la información que TuAppto almacena sobre sus Usuarios, o aquellos que visitan el Sitio Web y/o Aplicaciones Móviles como, asimismo, de los procesos y formas en la que dicha información es procesada y protegida."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Al registrarse, visitar y/o navegar o hacer uso de cualquiera de los Servicios provistos en el Sitio Web y/o Aplicaciones Móviles usted otorga su consentimiento para el procesamiento de los Datos Personales que nos proporciona de acuerdo con las estipulaciones aquí contenidas y la Ley N° 18331. De esta forma, la presente Política de Privacidad constituirá un acuerdo válido entre TuAppto y el Usuario que libremente utiliza los Servicios. Del mismo modo, la utilización de los Servicios implica la aceptación por parte del Usuario de los Términos y Condiciones a los que se anexa la presente Política de Privacidad."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Definiciones"
          subtitle="Para todos los efectos se entenderá por:"
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="1. Datos de Carácter Personal o Datos Personales: los relativos a cualquier información concerniente a personas naturales, identificadas o identificables."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="2. Datos Sensibles: aquellos datos personales que se refieren a las características físicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, tales como los hábitos personales, el origen racial, las ideologías y opiniones políticas, las creencias o convicciones religiosas, los estados de salud físicos o psíquicos y la vida sexual."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="3. Encargados del Procesamiento: personas autorizadas por TuAppto para acceder a los Datos Personales."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="4. Fuentes Accesibles al Público: sin perjuicio de lo dispuesto en la letra i) del artículo segundo de la Ley de Protección de Datos, se entenderá además como Fuentes Accesibles al Público, a toda base de datos cuya consulta pueda ser realizada por cualquier persona, sin más requisito que, en su caso el pago de una contraprestación y cuya consulta este abierta al público en general."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="5. Almacenamiento de Datos: la conservación o custodia de datos en un registro o banco de datos."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="6. Comunicación o Transmisión de Datos: dar a conocer de cualquier forma los datos de carácter personal a personas distintas del titular, sean determinadas o indeterminadas."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="7. Titular de los Datos: la persona natural a la que se refieren los datos de carácter personal."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="8. Procesamiento de Datos, cualquier operación o complejo de operaciones o procedimientos técnicos, de carácter automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, seleccionar, extraer, confrontar, interconectar, disociar, comunicar, ceder, transferir, transmitir o cancelar datos de carácter personal, o utilizarlos en cualquier otra forma."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="9. Conceptos definidos en los Términos y Condiciones: Estos mantendrán el mismo significado que los indicados en los Términos y Condiciones."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Responsable del procesamiento de los Datos Personales."
          subtitle="TuAppto,"
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Quebracho 2717 – Montevideo - Uruguay"
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Correo electrónico de contacto: info@tuappto.com"
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Autorización para el Procesamiento de Datos Personales"
          subtitle="En virtud Ley N° 18331 mediante la utilización de los Servicios o el registro en el Sitio Web y/o Aplicaciones Móviles, el Usuario autoriza expresamente a TuAppto a tratar los Datos Personales que nos proporcione, sean éstos a través del Sitio Web y/o Aplicaciones Móviles o cualquier tipo de comunicación electrónica o en soporte material que pueda llegar a ser utilizada para los fines propios de los Servicios."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Para estos efectos, TuAppto podrá almacenar Datos Personales tales como su nombre, domicilio, teléfono, correo electrónico, geolocalización, preferencias de búsqueda, uso y visitas al Sitio Web y/o Aplicaciones Móviles, hábitos de navegación, o cualquier otro necesario o vinculado a la prestación de los Servicios. Todos los Datos Personales solicitados por TuAppto son obligatorios y la negativa a proporcionarlos podrá imposibilitar que TuAppto pueda proceder a la prestación del Servicio. En los casos en los que TuAppto, a través de su Sitio Web y/o Aplicaciones Móviles indique específicamente que ciertos Datos Personales no son obligatorios, los Usuarios serán libres de no comunicar tales Datos Personales sin que esto tenga consecuencia alguna sobre la disponibilidad o el funcionamiento del Servicio. Los Usuarios que tengan dudas sobre qué Datos Personales son obligatorios pueden contactar con el responsable el procesamiento de los Datos Personales."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="En razón a lo anterior, al aceptar la presente Política de Privacidad, el Usuario garantiza y se obliga a que toda la información que entregue sea veraz, exacta, vigente y auténtica. Adicionalmente, el Usuario se hace responsable de mantener su información permanentemente actualizada. TuAppto no se responsabiliza por los errores o por la falta de veracidad de los datos provistos por el Usuario."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Asimismo, mediante la utilización de los Servicios o Sitio Web y/o Aplicaciones Móviles, el Usuario autoriza expresamente a TuAppto a utilizar los Datos de Carácter Personal proporcionados, con el objeto de obtener y/o recolectar sus Datos Personales disponibles en Fuentes Accesibles al Público, según este término se define en la letra i) del artículo segundo de la Ley de Protección de Datos, tales como aquellos registros disponibles por el Registro Civil, Servel, Poder Judicial, Google, entre otros."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="El Usuario asume la responsabilidad respecto de los Datos Personales de terceros que se obtengan, publiquen o compartan a través del Sitio Web y/o Aplicaciones Móviles y declara por la presente que tiene el consentimiento de dichos terceros para proporcionar dichos Datos a TuAppto."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Fines del Procesamiento"
          subtitle="Los Datos Personales de los Usuarios serán tratados para los fines propios y conexos a los Servicios ofrecidos en el Sitio. Asimismo, TuAppto podrá utilizar los Datos Personales para uso exclusivo suyo, de sus asociados y de las entidades a que está afiliada, con fines estadísticos, de tarificación u otros de beneficio general de aquéllos."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Entro los mismos se encuentra el procesamiento anonimizado de datos genéricos con fines de generar bases de datos estadísticos para estudios respecto de las necesidades propias de las comunidades de viviendas, edificios, y condominios."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Adicionalmente los datos serán recolectados para el ofrecimiento posterior de bienes y servicios por terceros, asociados al rubro sobre el cual se desempeña TuAppto, usualmente asociados a servicios para la vivienda, edificios y comunidades."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Los Datos relativos al Usuario son recogidos para permitir TuAppto prestar su Servicio, cumplir sus obligaciones legales, responder a solicitudes de ejecución, proteger sus derechos e intereses (o los de sus Usuarios o terceros), así como para detectar cualquier actividad maliciosa o fraudulenta."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Los Usuarios aceptan que los Datos Personales que se entreguen dentro del marco de la relación contractual con TuAppto, podrán ser utilizados para comunicarles ofertas posteriores y distintas a las actualmente ofrecidas en el Sitio Web y/o Aplicaciones Móviles de TuAppto."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Modalidad y lugar de procesamiento de los Datos Personales recogidos"
          subtitle="TuAppto procesará los Datos Personales de los Usuarios de manera adecuada y adoptará las medidas de seguridad apropiadas para impedir el acceso, la revelación, alteración o destrucción no autorizados de los Datos Personales."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="El procesamiento de Datos Personales se realizará mediante ordenadores y/o herramientas informáticas, siguiendo procedimientos y modalidades organizativas estrictamente relacionadas con las finalidades señaladas."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Además de TuAppto, en algunos casos podrán acceder a los Datos Personales ciertas categorías de personas autorizadas, relacionadas con el funcionamiento de los Servicios prestados por TuAppto (administración, ventas, marketing, departamento jurídico y de administración de sistemas) o contratistas externos que presten servicios a TuAppto (tales como proveedores externos de servicios técnicos, empresas de mensajería, empresas de hosting, empresas de informática, agencias de comunicación) que serán nombrados por TuAppto como Encargados del Procesamiento, si fuera necesario. Se podrá solicitar a TuAppto en cualquier momento una lista actualizada de dichas personas."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Datos provistos por el Administrador."
          subtitle="En algunos casos, el Administrador podrá proveer los Datos Personales necesarios para que TuAppto pueda crear el perfil de los Usuarios, quienes al momento de ingresar al Sitio Web y/o Aplicaciones Móviles de TuAppto deberán, aceptar los presentes Términos y Condiciones y Política de Privacidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="El Administrador que provea a TuAppto con cualquier tipo de información de sus Vecinos, declara que ha obtenido el consentimiento de todas y cada una de las personas Titulares de los Datos compartidos para compartir dicha información con TuAppto."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Base jurídica del Procesamiento"
          subtitle="TuAppto podrá procesar los Datos Personales del Usuario, siempre y cuando cumpla una de las siguientes condiciones:"
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="1. Cuando los Usuarios hayan dado su consentimiento para una o más finalidades específicas."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="2. Cuando la obtención de Datos Personales sea necesaria para el cumplimiento de un contrato entre el Usuario y/o cualquier otra obligación precontractual del mismo;"
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="3. Cuando el procesamiento sea necesario para el cumplimiento de una obligación legal de obligado cumplimiento por parte del Usuario;"
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="4. Cuando el procesamiento esté relacionado con una tarea ejecutada en interés público o en el ejercicio de competencias oficiales otorgadas a TuAppto."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="5. Cuando el procesamiento sea necesario con el fin de un interés legítimo perseguido por TuAppto o un tercero."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="En cualquier caso, TuAppto está a su disposición para definir las bases jurídicas específicas que se aplican al procesamiento y en particular, si la obtención de los Datos Personales es un requisito contractual o estatutario o un requisito necesario para formalizar un contrato."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Lugar de procesamiento de Datos Personales"
          subtitle="Los Datos Personales se procesan en las oficinas de TuAppto, así como en cualquier otro lugar en el que se encuentren situadas las partes implicadas en dicho proceso de procesamiento."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Dependiendo de la localización de los Usuarios, las transferencias de Datos Personales pueden implicar la transferencia de los Datos Personales de los Usuarios a otro país diferente al suyo propio. Para más información sobre el lugar de procesamiento de dichos Datos Personales transferidos, los Usuarios podrán consultar la sección que contiene los detalles sobre el procesamiento de los Datos Personales."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Período de conservación"
          subtitle="Los Datos Personales serán tratados y conservados durante el tiempo necesario y para la finalidad por la que han sido recogidos."
        />
        <BasicSubtitle :secondary="true" subtitle="Por lo tanto:" />
        <BasicSubtitle
          :secondary="true"
          subtitle="1. Los Datos Personales recogidos para la formalización de un contrato entre TuAppto y el Usuario deberán conservarse como tales hasta en tanto dicho contrato se haya formalizado por completo."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="2. Los Datos Personales recogidos en legítimo interés de TuAppto deberán conservarse durante el tiempo necesario para cumplir con dicha finalidad. Los Usuarios pueden encontrar información específica relacionada con el interés legítimo de TuAppto consultando las secciones relevantes del presente documento o contactando con TuAppto en el correo electrónico de contacto."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="TuAppto podrá conservar los Datos Personales durante un periodo adicional cuando el Usuario preste su consentimiento a tal procesamiento, siempre que dicho consentimiento siga vigente. Además, TuAppto estará obligado a conservar Datos Personales durante un periodo adicional siempre que se precise para el cumplimiento de una obligación legal o por orden que proceda de la autoridad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Una vez terminado el período de conservación, los Datos Personales deberán eliminarse. Por lo tanto, los derechos de acceso, modificación, rectificación y portabilidad de datos no podrán ejercerse una vez haya expirado dicho periodo."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Cambio en el Administrador de un edificio o complejo."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="En virtud del presente acuerdo, el Administrador de un edificio o complejo que suscribe esta política de privacidad de datos, acepta que, en caso de que este termine su relación con la comunidad que administra, dicho Administrador, accede por medio del presente, a que TuAppto mantenga y transfiera los Datos Personales de los Usuarios del edificio o complejo que administraba, a la cuenta que pueda suscribir un nuevo Administrador del edificio o complejo la en el futuro. Lo anterior deberá ocurrir dentro de un plazo máximo de 10 meses contados desde el término de la relación entre el Administrador y la comunidad, en caso de que esto no ocurra dentro de dicho plazo, los Datos Personales deberán eliminarse."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="En virtud del presente acuerdo, el Usuario que suscribe esta política de privacidad de datos, acepta que, en caso de que el Administrador de su comunidad termine su relación con la comunidad, TuAppto mantenga y transfiera sus Datos Personales, a la cuenta que pueda suscribir un nuevo Administrador de la comunidad en el futuro. Lo anterior deberá ocurrir dentro de un plazo máximo de 10 meses contados desde el término de la relación entre el Administrador y la comunidad, en caso de que esto no ocurra dentro de dicho plazo, los Datos Personales deberán eliminarse."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Procesamiento de Datos Personales por Parte de Terceros"
          subtitle="En algunos casos los Datos Personales podrán ser tratados por empresas relacionadas o terceros, por si o a través de proveedores, con el objeto de que Proveedores los utilicen en sus procedimientos internos para la contratación y oferta de servicios."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Derechos de los Usuarios"
          subtitle="El Usuario tendrá, sin limitación alguna, todos los derechos que la ley vigente contemple, especialmente, aquellos contenidos en la Ley N° 18331 en sus artículos 12 y siguientes. Especialmente los Usuarios tienen derecho a hacer lo siguiente:"
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="1. Retirar su consentimiento en cualquier momento."
          subtitle="Los Usuarios tienen derecho a retirar su consentimiento cuando lo hubieran concedido con anterioridad para el procesamiento de sus Datos Personales."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="2. Objeción al procesamiento de sus Datos."
          subtitle="Los Usuarios tienen derecho a oponerse al procesamiento de sus Datos Personales si dicho procesamiento se lleva a cabo con arreglo a una base jurídica distinta del consentimiento."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="3. Acceso a sus Datos Personales."
          subtitle="Los Usuarios tienen derecho a conocer si sus Datos Personales serán tratados por TuAppto, a obtener información sobre ciertos aspectos del procesamiento, además de obtener una copia de los Datos Personales objeto del procesamiento."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="4. Verificar y solicitar la modificación."
          subtitle="Los Usuarios tienen derecho a verificar la exactitud de sus Datos Personales y solicitar que los mismos se actualicen o corrijan."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="5. Restringir el procesamiento de sus Datos Personales."
          subtitle="Los Usuarios tienen derecho, en ciertos supuestos, a restringir el procesamiento de sus Datos Personales. En ese supuesto, TuAppto procesará sus Datos Personales con el único propósito de almacenarlos."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="6. Borrar o eliminar los Datos Personales."
          subtitle="Los Usuarios tienen derecho, en ciertos supuestos, a obtener la eliminación de sus Datos Personales por parte de TuAppto."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="7. Recibir sus Datos Personales y transferirlos a otro responsable."
          subtitle=" Los Usuarios tienen derecho a recibir sus Datos Personales en un formato estándar, estructurado, mecánicamente legible y, si fuera técnicamente posible, a que se dé traslado de los mismos a otro responsable sin ningún impedimento. Esta provisión será de aplicación siempre que los Datos Personales se hayan tratado a través de medios automatizados y que el procesamiento se base en el consentimiento del Usuario, en un contrato del que el Usuario forme parte o que aparezca en las obligaciones precontractuales del mismo."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Poner una reclamación."
          subtitle="Los Usuarios tienen derecho a poner una reclamación ante la autoridad competente en materia de protección de datos de carácter personal."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Cuando el procesamiento de los Datos Personales sea de interés público, en el ejercicio de competencias oficiales otorgadas a TuAppto o con motivo de un interés legítimo de TuAppto, los Usuarios podrán oponerse a dicho procesamiento explicando un motivo con relación a su situación particular para justificar su objeción."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Los Usuarios deben saber que, sin embargo, en caso de que sus Datos Personales sean tratados con fines comerciales, pueden oponerse en cualquier momento a tal procesamiento sin necesidad de justificación. Para saber si los Datos Personales de los Usuarios están siendo tratados por parte de TuAppto para fines comerciales, éstos deberán consultar las secciones relevantes del presente documento."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="El ejercicio de los derechos indicados precedentemente será siempre y en todo caso, gratuito, y deberán ser tramitados en tiempo y forma razonable, y la respuesta deberá ser inteligible para el Usuario."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Cualquier solicitud para ejercer los derechos del Usuario puede dirigirse TuAppto a través de los datos de contacto facilitados en el presente documento. Dichas solicitudes serán tramitadas por el TuAppto sin coste alguno tan pronto como le sea posible y siempre dentro del plazo de un mes."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Información adicional sobre la recogida de Datos Personales y su procesamiento"
          subtitle="Los Datos Personales del Usuario podrán ser utilizados para la defensa jurídica de TuAppto ante un tribunal o en las fases judiciales previas a un posible pleito derivado del uso inapropiado del Sitio Web y/o Aplicaciones Móviles o de los Servicios relacionados. El Usuario declara conocer que TuAppto puede ser requerido por las autoridades públicas a fin de revelar Datos Personales."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Por motivos relativos al funcionamiento y mantenimiento, este Sitio Web y/o Aplicaciones Móviles y cualquier otro servicio, proporcionado por terceros, que se utilice, podrá recoger un registro del sistema; es decir, archivos que registren la interacción con este Sitio Web y/o Aplicaciones Móviles y que puedan contener Datos Personales, tales como la dirección IP del Usuario."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Cookies"
          subtitle="El Sitio Web y/o Aplicaciones Móviles podrá usar cookies, propias o de terceros, a fin de permitir el adecuado funcionamiento de la plataforma, así como de la prestación de los Servicios. Es decisión del Usuario permitir o no el uso de las cookies, pero en el caso de no permitirlo, su acceso a ciertas secciones o funcionalidades del Sitio Web y/o Aplicaciones Móviles o del Servicio podría verse limitado."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Contacto"
          subtitle="Si el Usuario aún tiene dudas acerca de la forma en que recolectamos Datos Personales, sus fines o los derechos que tiene como titular de los mismos, no dude en contactarnos al correo electrónico: info@tuappto.com"
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Modificación de la presente Política de Privacidad"
          subtitle="TuAppto se reserva el derecho de modificar esta Política de Privacidad en cualquier momento, notificando a los Usuarios a través de esta página y/o de ser técnica y legalmente posible y comercialmente razonable, notificando directamente a los Usuarios, en caso de que TuAppto cuente con la información de contacto necesaria a tal fin. Se recomienda encarecidamente que revisen esta página con frecuencia, tomando como referencia la fecha de la última actualización indicada al final de la página."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="En el caso de que los cambios afectasen a las actividades de procesamiento realizadas en base al consentimiento del Usuario, TuAppto deberá obtener, si fuera necesario, el nuevo consentimiento del Usuario."
        />
      </div>
    </div>
  </div>
</template>

<script>
import BasicLogo from "../../widgets/logo/BasicLogo";
import BasicTitle from "../../widgets/title/BasicTitle.vue";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle.vue";
import store from "../../store";
import { useRouter } from "vue-router";

export default {
  components: {
    BasicLogo,
    BasicTitle,
    BasicSubtitle,
  },
  setup() {
    const router = useRouter();

    return { store };
  },
};
</script>

<style scoped>
.content-title {
  margin-top: 4rem;
}
.box-content {
  padding: 0 10vw;
}
</style>
